.allCard{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.card{
    color:white;
    background-color:rgba(0, 0, 0, 0.3);
    margin: 10px;
    padding: 10px;
    width: 45%;
}
/* Стили для выбранного заголовка столбца */
.selected-column {
    background-color: #afacb3 !important; /* Цвет для заголовка */
    color: #000 !important;
  }
  
  /* Стили для ячеек выбранного столбца */
  .selected-cell {
    background-color: #bebac4 !important; /* Цвет для ячеек */
  }
  
  .selected-row {
    background-color: #f1f8e9 !important; /* Цвет подсвеченных строк */
  }
  