body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0; 
  outline: 0;

}

#main{
  height: auto;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  }